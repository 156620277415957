<template>
  <v-dialog v-model="dialog" width="84%" class="dialog abc" persistent>
    <div class="dialog_content">
      <PageTitle :title="title" />
      <slot></slot>
      <!-- <div @click="$emit('close')">close</div> -->
    </div>
  </v-dialog>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "Dialog",
  components: {
    PageTitle,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // dialog: false,
    };
  },
};
</script>

<style lang="scss">
.dialog {
  &_content {
    // @include vertical-center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 25px 30px;
    background-color: #fff;
    color: #333;
    border-radius: 20px;
    text-align: center;
  }
}
</style>
